<template>
  <div>
    <div class="bar" :style='style'></div>
    <div class="p-wrapper">
      <slot></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ReadingProgressBar',
  props: ['background'],
  data() {
    return {
      style: null
    }
  },
  mounted() {
    this.$nextTick(function () {
      var theElement = document.getElementsByClassName('p-wrapper')[0];
      var backColor = this.background;

      function calc() {
        var top = theElement.getBoundingClientRect().top - (window.innerHeight / 300);
        var height = theElement.getBoundingClientRect().height;
        var calculatedValue = Math.abs(top) / height * 110;

        if (top < 0) {
          this.style = 'width:' + calculatedValue + '%; background:' + backColor;
        } else {
          this.style = 'width:0';
        }
        if (calculatedValue >= 100) {
          this.$emit('complete')
        }
      }

      window.addEventListener('scroll', calc.bind(this));    // scroll
      window.addEventListener('resize', calc.bind(this));    // resize

    });
  },
}
</script>

<style>
.p-wrapper {
  overflow: hidden !important;
}

.bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 6px;
  box-shadow: 0 2px 5px 0 #828282;
  z-index: 5;
  border-top-right-radius: 55px;
  border-bottom-right-radius: 55px;
  background: rgb(69, 155, 201);
  background: linear-gradient(122deg, rgba(69, 155, 201, 1) 29%, rgba(122, 104, 170, 1) 56%, rgba(200, 35, 124, 1) 76%);
}

@media only screen and (max-width: 425px) {
  .bar {
    display: none;
  }
}
</style>