
<template>
  <div class="team-section-page">
    <div class="hero-team" :style="{ backgroundImage: 'url(' + backgroundHeroTeam + ')'}">
      <div class="container-info-generic">

        <div class="ext-bg-overlay">
          <div class="romb div1"></div>
          <div class="div2 romb"></div>
          <div class="div3 romb"></div>
          <div class="div4 romb"></div>
          <div class="div5 romb"></div>
        </div>


        <div class="container-left">
          <div class="container-left-swipe">
            <h4 class="container-left-swipe_title">{{company}}</h4>
          </div>
          <div class="container-left-swipe container-left-swipe--delay">
            <p class="container-left-swipe_subtitle">{{ messageCompany }}</p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
/* eslint-disable */
import heroteam from '@/assets/team/hero-team-img.webp';
import TeamSlider from "../../components/Team/TeamSlider";
import Footer from "../../components/Home/Footer";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import ApplyNow from "../../components/Team/ApplyNow";
import $ from "jquery";
import DropDownFooter from "../../components/FooterMobile/DropDownFooter";
import DropDown from "../../components/FooterMobile/DropDown";

export default {

  name: 'HomePage',

  components: { DropDown, DropDownFooter, ApplyNow, TeamSlider, Footer},

  data() {
    return {
      backgroundHeroTeam: heroteam,
          company: 'Scriptics',
        messageCompany: 'A creative company like no other',
    }
  },

  mounted() {
    $(document).ready(function () {
      animateDiv();
      animateDiv2();
      animateDiv3();
      animateDiv4();
      animateDiv5();

    });
    // parallax effect on the header img
    gsap.to('.hero-team .container-info-generic', {
      y: '80%',
      ease: Power0.easeNone,
      scrollTrigger: {
        trigger: '.hero-team',
        start: 'top 1px',
        end: 'bottom 100px',
        scrub: true,
        markers: false,
      },
    });

    gsap.to('.hero-team .container-info-generic', {
      filter: 'brightness(0.25) blur(16px)',
      ease: Power0.easeNone,
      scrollTrigger: {
        trigger: '.hero-team',
        start: 'center top',
        scrub: true,
      },
    });

    // the header title
    gsap.to('.hero-team .container-left-swipe_title', {
      y: '99vh',
      ease: Power0.easeNone,
      scrollTrigger: {
        trigger: '.hero-team',
        start: '25% top',
        scrub: true,
      }
    });

    function makeNewPosition() {


      var h = $(window).height();
      var w = $(window).width();

      var nh = Math.floor(Math.random() * h);
      var nw = Math.floor(Math.random() * w);

      return [nh, nw];

    }

    function animateDiv() {
      var newq = makeNewPosition();
      var oldq = $('.ext-bg-overlay .div1').offset();
      var speed = calcSpeed([oldq.top, oldq.left], newq);

      $('.ext-bg-overlay .div1').animate({top: newq[0], left: newq[1]}, speed, function () {
        animateDiv();
      });

    }

    function animateDiv5() {
      var newq = makeNewPosition();
      var oldq = $('.ext-bg-overlay .div5').offset();
      var speed = calcSpeed([oldq.top, oldq.left], newq);

      $('.ext-bg-overlay .div5').animate({top: newq[0], left: newq[1]}, speed, function () {
        animateDiv5();
      });

    }

    function animateDiv4() {
      var newq = makeNewPosition();
      var oldq = $('.ext-bg-overlay .div4').offset();
      var speed = calcSpeed([oldq.top, oldq.left], newq);

      $('.ext-bg-overlay .div4').animate({top: newq[0], left: newq[1]}, speed, function () {
        animateDiv4();
      });

    }

    function animateDiv3() {
      var newq = makeNewPosition();
      var oldq = $('.ext-bg-overlay .div3').offset();
      var speed = calcSpeed([oldq.top, oldq.left], newq);

      $('.ext-bg-overlay .div3').animate({top: newq[0], left: newq[1]}, speed, function () {
        animateDiv3();
      });

    }

    function animateDiv2() {
      var newq = makeNewPosition();
      var oldq = $('.ext-bg-overlay .div2').offset();
      var speed = calcSpeed([oldq.top, oldq.left], newq);

      $('.ext-bg-overlay .div2').animate({top: newq[1], left: newq[1]}, speed, function () {
        animateDiv2();
      });

    }

    function calcSpeed(prev, next) {

      var x = Math.abs(prev[1] - next[1]);
      var y = Math.abs(prev[0] - next[0]);

      var greatest = x > y ? x : y;

      var speedModifier = 0.2;

      var speed = Math.ceil(greatest / speedModifier);

      return speed;

    }
  }

}

</script>

<style scoped lang="scss">
body {
  overflow: hidden!important;
}
.team-section-page {
  display: block;
  overflow: hidden!important;
}

.hero-team {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
}

.romb {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  border-radius: 20px;
}

.container-info-generic {
  width: 80%;
}

.ext-bg-overlay {
  background: transparent;
  display: block;
  overflow: hidden!important;
}

.ext-bg-overlay .div1 {
  width: 15.75em;
  height: 15.75em;
  background-color: rgba(255, 255, 255, .3);
  left: 75%;
  top: 95%;
}

.ext-bg-overlay .div2 {
  width: 20em;
  height: 20em;
  left: 100%;
  top: 40%;
  background-color: rgba(255, 255, 255, .1);
}

.ext-bg-overlay .div3 {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  border: 1px solid  rgba(255, 255, 255, .5);
  background-color: rgba(255, 255, 255, .8);
  left: 65%;
  top: 40%;
}

.ext-bg-overlay .div4 {
  border-radius: 10%;
  width: 7em;
  height: 7em;
  border: 3px solid #2d98c8;
  left: 15%;
  top: 40%;
  background-color: rgba(255, 255, 255, .05);
}

.ext-bg-overlay .div5 {
  border-radius: 5%;
  width: 25em;
  height: 25em;
  border: 3px solid #2d98c8;
  left: 0;
  top: 40%;
  background-color: rgba(255, 255, 255, .05);
}

.container-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  font-weight: 900;
  padding-left: 40px;
}

.container-left-swipe {
  position: relative;
  width: fit-content;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    width: 0;
    height: 100%;
    background-color: #fafafa;
    animation: swipe 1.5s ease-out 1s forwards;
  }

  &--delay {
    &::after {
      background-color: #2d98c8;
      animation-delay: 1.5s;
    }
  }
}

.container-left-swipe_title {
  font-size: 7rem;
  opacity: 0;
  color: #fafafa;
  animation: fade 0.01s ease-out 1.75s forwards;
  font-family: "Heebo", sans-serif;
}

.container-left-swipe_subtitle {
  font-size: 2.01rem;
  color: #2d98c8;
  opacity: 0;
  animation: fade 0.01s ease-out 2.25s forwards;
  text-shadow: 2.5px 2.5px 4px #000000;
  font-family: "Heebo", sans-serif;
  text-align: center;
}

.container-left-swipe_title, .container-left-swipe_subtitle {
  line-height: 1;
  display: block;
}
.container-left-swipe_subtitle p {
  margin-left: 11px;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes swipe {
  0% {
    right: 100%;
    left: 0;
    width: 0%;
  }
  50% {
    right: 0;
    left: 0;
    width: 100%;
  }
  100% {
    right: 0;
    left: 100%;
    width: 0%;
  }
}


@media only screen and (max-width: 1024px) {
  .hero-team {
    background-position: 96%;
  }
  .container-left-swipe_title {
    font-size: 5.5rem;
  }
  .container-left-swipe_subtitle {
    font-size: 1.55rem;
  }
}

@media screen and (max-width: 768px) {
  .container-left-swipe_title {
    font-size: 4rem;
  }
  .container-left-swipe_subtitle {
    font-size: 22px;
    line-height: 1.4
  }
  .fullpage__content {
    display: grid;
    grid-template-columns: minmax(100%, 100%) auto;
    position: relative;
    height: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .ext-bg-overlay {
    display: none;
  }
  .container-left {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
  }
  .container-info-generic {
    width: 100%;
  }
  .container-left {
    width: 100%;
    justify-content: unset;
    padding: 11vh 0;
  }
  .hero-team {
    background-position: 88%;
  }
}

@media only screen and (max-width: 375px) {
  .hero-team {
    background-position: 85%;
  }
}

@media only screen and (max-width: 320px) {
  .hero-team {
    background-position: 83%;
    transform: scale(1.10);
  }
}
</style>
