<template>
  <div class="slider" ref="slider">
    <div class="slider__controls">
      <a href="#"
          v-for="(employee, index) in allEmployees.length"
          :key="index"
          class="slider__controls__link"></a>
    </div>

    <section v-for="(employee, index) in allEmployees" :key="index">

      <div class="fullpage__content" v-if="index % 2 === 0">

        <img :src="`${link}${employee.picture}`" alt="employee">

        <div class="fullpage-right-side__content" >
          <h1 class="right-side__content-name" :id="employee.name">{{employee.name}}</h1>
          <h1 class="right-side__content-title">{{employee.title}}</h1>
          <p v-html="employee.description"  class="right-side__content-text"></p>

<!--          <div class="social-links">-->
<!--            <a v-if="employee.linkedin" :href="employee.linkedin" target="_blank">-->
<!--              <i class="fa fa-linkedin-square fa_custom fa-2x" aria-hidden="true"></i>-->
<!--            </a>-->

<!--            <a v-if="employee.instagram" :href="employee.instagram" target="_blank">-->
<!--              <i class="fa fa-instagram fa_custom fa-2x" aria-hidden="true"></i>-->
<!--            </a>-->

<!--            <a v-if="employee.facebook" :href="employee.facebook" target="_blank">-->
<!--              <i class="fa fa-facebook-square fa_custom fa-2x" aria-hidden="true"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

      </div>

      <div class="fullpage__content odd" v-if="index % 2 !== 0">

        <div class="fullpage-left-side__content">
          <h1 class="right-side__content-name">{{employee.name}}</h1>
          <h1 class="right-side__content-title">{{employee.title}}</h1>
          <p v-html="employee.description" class="right-side__content-text"></p>

<!--          <div class="social-links">-->
<!--            <a v-if="employee.linkedin" :href="employee.linkedin" target="_blank">-->
<!--              <i class="fa fa-linkedin-square fa_custom fa-2x" aria-hidden="true"></i>-->
<!--            </a>-->

<!--            <a v-if="employee.instagram" :href="employee.instagram" target="_blank">-->
<!--              <i class="fa fa-instagram fa_custom fa-2x" aria-hidden="true"></i>-->
<!--            </a>-->

<!--            <a v-if="employee.facebook" :href="employee.facebook" target="_blank">-->
<!--              <i class="fa fa-facebook-square fa_custom fa-2x" aria-hidden="true"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

        <img :src="`${link}${employee.picture}`" alt="employee">

      </div>

    </section>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import { CMS } from '@/main';

export default {

  name: "TeamSlider",

  computed: mapGetters(["allEmployees"]),

  data() {
    return {
      link: CMS,
    };
  },

  methods: {
    ...mapActions(['loadEmployees']),

    moveSlider(n) {
      //n = n === 1 ? 0 : n;
      n = n-1;
      this.slider.scrollTo({
        top: this.height * n,
        behavior: 'smooth'
      })
    }
  },

  created() {
    this.loadEmployees();
  },

};
</script>

<style lang="scss" scoped>

.slider {
  display: block;
  overflow: hidden;
}

.slider__controls {
  position: fixed;
  right: .5rem;
  top: 0;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1099;
  display: none;
}

.slider__controls__link {
  width: 15px;
  height: 15px;
  background-color: #fafafa;
  border-radius: 50%;
  display: flex;
  margin: 1em 0;
  opacity: .6;
  transition: .4s ease all;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  margin: 1rem 0;
  z-index: 1099;
  -webkit-box-shadow: 0px 0px 12px 0px #000000;
  box-shadow: 0px 0px 12px 0px #000000;
}

.slider__controls__link:hover {
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.37);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.37);
  transition: all 0.5s ease;

}

.fullpage__content {
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  img {
    width: 100%;
    height: 100%;
  }

}

.fullpage-right-side__content,
.fullpage-left-side__content {
  background: rgb(0, 103, 138);
  background: linear-gradient(91deg, #060606 35%, #2c4d58 80%);
  position: relative;

  .social-links {
    position: absolute;
    left: 30px;
    bottom: 15px;
    a {
      position: relative;
      color: #2d98c8;
      text-decoration: none;

      &:not(:first-of-type) {
        margin-left: 20px;
      }

      &:hover{
        color: #fafafa;
        transition: ease-out 4ms;
      }
    }
  }
}
.fullpage-left-side__content {
  background: linear-gradient(91deg, #2c4d58 35%, #060606 80%);
}
.right-side__content-name {
  font-size: 3em;
  margin: 4rem 0 0 30px;
  color: #fafafa;
  font-weight: 900;
  font-family: "Heebo", sans-serif;
}

.right-side__content-title {
  color: #2d98c8;
  font-size: 2em;
  padding-left: 30px;
  font-family: "Heebo", sans-serif;

}

.right-side__content-text {
  color: #fafafa;
  font-size: 1.4em;
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Heebo", sans-serif;
}

.right-side__content-name,
.right-side__content-title,
.right-side__content-text {
  text-align: left;
  line-height: 1.4em;
}

@media only screen and (max-width: 1024px) {

  .fullpage__content {

    .fullpage-right-side__content,
    .fullpage-left-side__content {
      background: rgb(0, 103, 138);
      background: linear-gradient(
              162deg, #2c4d58 35%, #060606 80%);
      position: relative;

      .social-links {
        position: relative;
        margin-top: 50px;
      }
    }

  }

}

@media only screen and (max-width: 768px) {

  .fullpage__content {
    grid-template-columns: 100%;
    .fullpage-left-side__content {
      background: linear-gradient(
              6deg, #2c4d58 35%, #060606 80%);
      padding-bottom: 20px;
    }
    .fullpage-right-side__content {
      background: linear-gradient(
              6deg, #2c4d58 35%, #060606 80%);
      padding-bottom: 20px;
    }
    &.odd {

      .fullpage-left-side__content {
        grid-row: 2;

      }

      img {
        grid-row: 1;
      }

    }
  }

}

</style>
