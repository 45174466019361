<template>
  <div class="container-above-footer-team">
    <div class="grid-container">
      <div class="container-above-footer_text">
        <h3 class="container-above-footer_title">If you want your photo here</h3>
        <div id="container-button-apply">
          <button id="button-apply" v-on:click.prevent="showPanelHr">
            Apply now
          </button>
          <slideout-panel></slideout-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplyNow",
  data() {
    return {
      panel1Form: {
        openOn: "left"
      },
    }
  },
  methods: {
    showPanelHr() {
      const panel = this.$showPanel({
        component: "PanelHr",
        cssClass: "Panel",
        cssClass: "calendar-panel",
        props: {}
      });
    },
  }
}
</script>

<style lang="scss" scoped>

.container-above-footer-team {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  background-color: #1f232f;
  height: 20em;
}

.grid-container {
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.container-above-footer_text {
  width: 80%;
  text-align: center;
  color: #fafafa;
  margin: 0 auto;
}

.container-above-footer_text h3 {
  font-size: 2.4rem;
  line-height: 1.8em;
  margin: 5vh 0;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
}

#button-apply {
  display: inline-block;
  border-radius: 99px;
  z-index: 1;
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #2d98c8;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 45px 10px 45px;
  letter-spacing: 2px;
  font-family: "Heebo", sans-serif;
  width: 15em;
}

#button-apply:before, #button-apply:after {
  content: ' ';
  position: absolute;
  border-radius: 99px;
}

#button-apply:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  background: #1f232f;
  background: -moz-linear-gradient(left, #1f232f 0%, #2d98c8 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #1f232f), color-stop(100%, #2d98c8));
  background: -webkit-linear-gradient(left, #1f232f 0%, #2d98c8 100%);
  background: -o-linear-gradient(left, #1f232f 0%, #2d98c8 100%);
  background: -ms-linear-gradient(left, #1f232f 0%, #2d98c8 100%);
  background: linear-gradient(to left, #1f232f -2%, #2d98c8 41%);
}

.slideout-panel .slideout-wrapper .slideout.open-on-left {
  right: auto;
  left: 0;
  background: rgb(0, 103, 138);
  background: linear-gradient(162deg, rgba(0, 103, 138, 1) 35%, rgba(30, 35, 47, 1) 80%)
}

#button-apply:after {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  z-index: -2;
  background-color: #1f232f;
  opacity: 2;
  transition: all 0.6s ease-in-out;
}

#button-apply:hover {
  color: #fafafa;
  transition: all 0.5s ease;
}

#button-apply:hover:after {
  opacity: 0;
}

@media only screen and (max-width: 500px) {
  .container-above-footer_text h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 425px) {
  .container-above-footer-team h3 {
    margin: 19px 0;
    margin-bottom: 50px;
  }
  #button-apply {
    width: 20em;
  }
}

</style>
